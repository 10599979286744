<template>
  <div>

    <v-card>
      <v-card-title>Listado de distancias</v-card-title>
      <v-card-text>

        <botonera :botones="botones" alinear="right"
        @crear="dialog = !dialog"
        @cargar="upload = !upload"
        @eliminar="deleteAll"
        ></botonera>

        
      </v-card-text>
    </v-card>


    <datatable :cabeceras="distanciasListHeaders" :items="distanciasListBody" titulo="Listado de Distancias" label="Escriba Para buscar Distancia" icono="list" color_icono="azul"
    :acciones="['editar','eliminar']"
    @editar="editDistancia($event)"
    @eliminar="deleteDistancia($event)">
    </datatable>

    <!-- <v-data-table 
        :headers="distanciasListHeaders"
        :items="distanciasListBody"
        class="elevation-1"
        no-data-text="No hay distancias"
        :search="search"
    >
      <template v-slot:[`item.actions`]="{item}">
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon
              medium
              class="mr-2"
              @click="editDistancia(item)"
              v-on="on"
            >
              mdi-pencil
            </v-icon>
          </template>
          <span>Editar datos del Distancia</span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon
                medium
                @click="deleteDistancia(item)"
                v-on="on"
            >
            mdi-delete
            </v-icon>
          </template>
          <span>Dar de baja Distancia</span>
        </v-tooltip>
      </template>
    </v-data-table> -->

    <!-- Modal Nuevo Distancia -->
    <v-dialog
      v-model="dialog"
      width="800px"
      @click:outside = "closeModal"
    >
      <v-card>
        <v-card-title class="blue darken-1">{{ title }}</v-card-title>
        <v-card-text>
          <v-row class="mx-2">
            <v-col cols="4">
              <v-select 
                prepend-icon="mdi-factory"
                v-model="distancia.acopio"
                :items="acopios"
                item-text="nombre"
                item-value="id"
                label="Acopio"
                color="blue darken-3"
                persistent-hint
                return-object
              >
              </v-select>
            </v-col>
            <v-col cols="4">
              <v-select 
                prepend-icon="mdi-factory"
                v-model="distancia.empresa"
                :items="empresas"
                item-text="razon_social"
                item-value="id"
                label="Cliente"
                color="blue darken-3"
                persistent-hint
                return-object
                @change="loadCentros"
              >
              </v-select>
            </v-col>
            <v-col cols="4">
              <v-select 
                prepend-icon="mdi-factory"
                v-model="distancia.centro"
                :items="centros"
                item-text="nombre"
                item-value="id"
                label="Centro"
                color="blue darken-3"
                persistent-hint
                return-object
              >
              </v-select>
            </v-col>
            <v-col
              class="align-center justify-space-between"
              cols="4"
            >
              <v-text-field
                prepend-icon="mdi-barcode"
                placeholder="Millas"
                v-model="distancia.millas"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
              <v-btn
                @click="closeModal"
              >Cerrar</v-btn>
              <v-btn
                color="primary"
                @click="saveDistancia"
              >Grabar</v-btn>
        </v-card-actions>
              
      </v-card>
    </v-dialog>
    <!-- / Modal -->

    <!-- Modal upload -->
    <v-dialog
      v-model="upload"
      width="600px"
    >
      <v-card>
        <v-card-title class="green darken-1">
          Subir listado de distancias desde excel
        </v-card-title>
        <v-card-text>
          <v-row class="mx-2">
            <v-file-input v-model="file" accept=".xls, .xlsx" label="Archivo Excel"></v-file-input>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="upload = false"
          >Cancelar</v-btn>
          <v-btn
            color="primary"
            @click="uploadDistancias"
          >Subir</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- / Modal -->
    
    <v-snackbar
      v-model="snackbar"
      :timeout="5000"
      :color="color"
    >
      {{ validation_message }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
  import {mapState, mapMutations} from 'vuex'

  export default {
    data: () => ({
      title: 'Nueva Distancia',
      color: 'green',
      snackbar: false,
      errors: [],
      validation_message: '',
      search: null,
      file: null,
      dialog: false,
      upload: false,
      acopios: [],
      empresas: [],
      centros: [],
      distanciaIndex: -1,
      distancia: {
        id: 0,
        acopio: null,
        empresa: {},
        centro: {},
        millas: '',
      },
      distanciasListHeaders: [
        {
          text: 'Id',
          align: 'center',
          sortable: true,
          value: 'id',
          class: "blue-grey darken-4 white-text"
        },
        {
          text: 'Acopio',
          align: 'center',
          sortable: true,
          value: 'acopio.nombre',
          class: "blue-grey darken-4 white-text"
        },
        {
          text: 'Cliente',
          align: 'center',
          sortable: true,
          value: 'centro.empresa.razon_social',
          class: "blue-grey darken-4 white-text"
        },
        {
          text: 'Centro',
          align: 'center',
          sortable: true,
          value: 'centro.nombre',
          class: "blue-grey darken-4 white-text"
        },
        {
          text: 'Millas',
          align: 'center',
          sortable: true,
          value: 'millas',
          class: "blue-grey darken-4 white-text"
        },
        {
          text: 'Acciones',
          align: 'center',
          sortable: false,
          value: 'actions',
          class: "blue-grey darken-4 white-text"
        },
      ],
      distanciasListBody: [],
      botones:[
        {boton:'crear',tooltip:'Nueva Distancia'},
        {boton:'cargar',tooltip:'Carga Masiva'},
        {boton:'eliminar',tooltip:'Borrar Todo'}
      ]
    }),
    mounted() {
      this.loadEmpresas();
      this.loadAcopios();
      this.loadDistancias();
    },
    methods:{
      async loadEmpresas(){
        let url = `${this.base_url}empresas`;
        await this.axios.get(url, this.headers).then((response)=>{
            this.empresas = response.data;
            this.distancia.empresa = this.empresas[0];
        }).catch((error)=>{
            console.log(error);
        });
      },
      async loadAcopios(){
        let url = `${this.base_url}acopios`;
        await this.axios.get(url, this.headers).then((response)=>{
            this.acopios = response.data;
            this.distancia.acopio = this.acopios[0];
        }).catch((error)=>{
            console.log(error);
        });
      },
      async loadCentros(){
        let url = `${this.base_url}centros/${this.distancia.empresa.id}`;
        await this.axios.get(url, this.headers).then((response)=>{
            this.centros = response.data;
            if(this.distancia.centro == undefined){
              this.distancia.centro = this.centros[0];
            }
        }).catch((error)=>{
            console.log(error);
        });
      },
      async loadDistancias(){
        this.mostrarLoading('Cargando listado de distancias...')
        let url = `${this.base_url}distancias`;
        await this.axios.get(url, this.headers).then((response)=>{
          this.distanciasListBody = response.data;
          this.ocultarLoading()
        }).catch((error)=>{
            console.log(error);
            this.ocultarLoading()
        });
      },
      async saveDistancia(){
        // validation
        this.errors = [];
        if(!this.distancia.millas || isNaN(this.distancia.millas) || this.distancia.millas <= 0){
          this.errors.push("Debe indicar las millas");
        }
        if(this.distancia.empresa == null){
          this.errors.push("Debe seleccionar una empresa");
        }
        if(this.distancia.centro == null){
          this.errors.push("Debe seleccionar un centro de cultivo");
        }
        if(this.distancia.acopio == null){
          this.errors.push("Debe seleccionar un centro de acopio");
        }
        if(this.errors.length > 0){
          this.validation_message = this.errors.join();
          this.showSnackBar(true);
          return;
        }
        let url = `${this.base_url}distancias`;
        let distanciaData = {
          'empresas_id': this.distancia.empresa.id,
          'centros_id': this.distancia.centro.id,
          'acopios_id': this.distancia.acopio.id,
          'millas': this.distancia.millas,
        };
        if(this.distancia.id > 0){
          url = url + "/" + this.distancia.id;
          await this.axios.put(url, distanciaData, this.headers).then((response)=>{
            let index = this.distanciasListBody.map(distancia => {
              return distancia.id;
            }).indexOf(this.distancia.id);
            this.distanciasListBody.splice(index, 1, response.data);
            this.showSnackBar(false);
          }).catch((error)=>{
              this.validation_message = error;
              this.showSnackBar(true);
          });
        }else{
          await this.axios.post(url, distanciaData, this.headers).then((response)=>{
              this.distanciasListBody.push(response.data);
              this.showSnackBar(false);
          }).catch((error)=>{
              this.errors = error.response.data;
              this.validation_message = this.errors.join();
              this.showSnackBar(true);
          });
        }
        this.cleanForm();
      },
      editDistancia(item) {
        this.distanciaIndex = this.distanciasListBody.indexOf(item);
        this.distancia = Object.assign({}, item);
        this.distancia.empresa = item.centro.empresa;
        this.dialog = true;
        this.loadCentros();
        this.title = "Editar Distancia";
      },
      async deleteDistancia(item) {
        this.$confirm('Desea borrar este distancia?').then(res => {
          if(res){
            let url = `${this.base_url}distancias/${item.id}`;
            this.axios.delete(url, this.headers).then((response)=>{
              console.log(response);
              const index = this.distanciasListBody.indexOf(item);
              this.distanciasListBody.splice(index, 1);
              this.showSnackBar(false);
            }).catch((error)=>{
              this.validation_message = error;
              this.showSnackBar(true);
            });
          }
        });
      },
      showSnackBar(error){
        if(error){
          this.color = 'red';
        }else{
          this.color = 'green';
          this.validation_message = "Ejecutado exitosamente!";
        }
        this.snackbar = true;
      },
      closeModal(){
        this.cleanForm();
        this.dialog = false;
        this.error = null;
        this.title = "Nueva Distancia";
      },
      cleanForm(){
        this.distancia.id = 0;
        this.distancia.acopio = null;
        this.distancia.centro = {};
        this.distancia.millas = "";
        this.dialog = false;
      },
      async uploadDistancias(){
        this.headers.headers['Content-Type'] = "multipart/form-data";
        let url = `${this.base_url}uploads/distancias`;
        let formData = new FormData();
        formData.append("excel", this.file);
        await this.axios.post(url, formData, this.headers).then((response)=>{
          console.log(response.data.data);
          this.file = null;
          this.upload = false;
          this.loadDistancias();
          this.showSnackBar(false);
        }).catch((response)=>{
          if(response.success == false && response.message == "Validation Error"){
            this.validation_message = "Error de validación";
            this.showSnackBar(true);
          }
        });
      },
      async deleteAll(){
        this.$confirm('Desea borrar todos los datos?').then(res => {
          if(res){
            let url = `${this.base_url}truncar/distancias`;
            this.axios.get(url, this.headers).then((response)=>{
              console.log(response);
              this.loadDistancias();
              this.showSnackBar(false);
            }).catch((error)=>{
              this.validation_message = error;
              this.showSnackBar(true);
            });
          }
        });
      },
      ...mapMutations(['actualizarBaseUrl', 'mostrarLoading', 'ocultarLoading']),
    },
    computed:{
      ...mapState(['loading', 'base_url', 'headers']),
      
    },
    components:{
      
    }
  }
</script>